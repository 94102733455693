import { BucketedActivitiesDto } from "../../features/ActivityBuckets/useActivityBuckets";
import { ActivityCountDto } from "../../features/ActivityCount/useActivityCount";
import { ClassRow } from "../../features/Classes/useClasses";
import { WorkoutLocation } from "../../features/LocationPicker/useLocations";
import { UserCountDto } from "../../features/UserCount/useUserCount";
import { BucketedSignupsDto } from "../../features/UsersOverTime/useBucketedSignups";
import { getAppConfig } from "../config/config";
import { RequestProps, isResponseValid } from "./sendRequest";

export abstract class IAPIClient {
    protected token: string;
    public setToken(token: string) {
        this.token = token;
    }
    abstract getAvailabilityHeatmap(input: { locationId: string }): Promise<{ data: number[]; name: string }[]>;
    abstract getBucketedSignups(input: { locationId: string; startTime: Date; endTime: Date }): Promise<BucketedSignupsDto>
    abstract getUserCount(input: { locationId: string; startTime: Date; endTime: Date }): Promise<UserCountDto>;
    abstract getLocations(): Promise<WorkoutLocation[]>;
    abstract getFriendRequestsCount(input: { locationId: string; startTime: Date; endTime: Date }): Promise<{ count: number }>
    abstract getClasses(input: { locationId: string; startTime: Date; endTime: Date }): Promise<ClassRow[]>;
    abstract getActivityBuckets(input: { locationId: string; startTime: Date; endTime: Date }): Promise<BucketedActivitiesDto>
    abstract getActivityHeatmap(input: { locationId: string; startTime: Date; endTime: Date }): Promise<{ data: number[]; name: string }[]>
    abstract getActivityCount(input: { locationId: string, startTime: Date; endTime: Date; }): Promise<ActivityCountDto>;
    protected async sendRequest<TOutput>(
        options: Partial<RequestProps>,
    ) {
        const {
            method,
            endpoint,
            onUnauthorized,
            contentType,
            additionalHeaders,
            data,
        } = {
            onUnauthorized: () => { },
            contentType: 'application/json',
            additionalHeaders: {},
            method: 'POST',
            ...options,
        };
        // console.log({ fetching: `${getAppConfig().apiURL}/${endpoint}`, token });
        const result = await fetch(`${getAppConfig().apiURL}/${endpoint}`, {
            method,
            body: method === 'GET' ? undefined : JSON.stringify(data || {}),
            headers: {
                ...additionalHeaders,
                'Content-Type': contentType,
                Authorization: this.token,
            },
        });

        if (result.status === 401) onUnauthorized();
        const jsonResponse = await result.json();
        if (!isResponseValid(result.status)) {
            throw new Error(jsonResponse.message);
        }

        return {
            status: result.status,
            json: jsonResponse as TOutput,
        };
    };
}