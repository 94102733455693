import { BucketedActivitiesDto } from "../../features/ActivityBuckets/useActivityBuckets";
import { ActivityCountDto } from "../../features/ActivityCount/useActivityCount";
import { ActivityHeatmapDto, daysOfWeek } from "../../features/ActivityHeatmap/useActivityHeatmap";
import { ClassRow } from "../../features/Classes/useClasses";
import { WorkoutLocation } from "../../features/LocationPicker/useLocations";
import { UserCountDto } from "../../features/UserCount/useUserCount";
import { BucketedSignupsDto } from "../../features/UsersOverTime/useBucketedSignups";
import { IAPIClient } from "./IAPIClient";
import { ActivityFixture, generateActivitiesFixture } from "./fixtures/generateActivities";
import { generateClassesFixture } from "./fixtures/generateClasses";
import { UserFixture, generateUsersFixture } from "./fixtures/generateUsers";

export class StaticApiClient extends IAPIClient {
    private usersFixture: UserFixture[];
    private activitiesFixture: ActivityFixture[];
    private classesFixture: ClassRow[];
    constructor() {
        super();
        this.usersFixture = generateUsersFixture();
        this.activitiesFixture = generateActivitiesFixture();
        this.classesFixture = generateClassesFixture();
    }
    private getUsersWithinRange({ startTime, endTime }: { startTime?: Date; endTime?: Date }) {
        return this.usersFixture.filter(user => (!startTime || user.signUpTime.getTime() >= startTime.getTime()) && (!endTime || user.signUpTime.getTime() <= endTime.getTime()));
    }
    private getActivitiesWithinRange({ startTime, endTime }: { startTime?: Date; endTime?: Date }) {
        return this.activitiesFixture.filter(user => (!startTime || user.startTime.getTime() >= startTime.getTime()) && (!endTime || user.startTime.getTime() <= endTime.getTime()));
    }
    private getClassesWithinRange({ startTime, endTime }: { startTime?: Date; endTime?: Date }) {
        return this.classesFixture.filter(classRow => (!startTime || classRow.startTime.getTime() >= startTime.getTime()) && (!endTime || classRow.startTime.getTime() <= endTime.getTime()));
    }
    getAvailabilityHeatmap(input: { locationId: string; }): Promise<{ data: number[]; name: string; }[]> {
        const availabilities = this.usersFixture
            .map((user) => user.availabilities)
            .filter((availability) => availability)
            .flat();


        const availabilityMatrix = availabilities.reduce(
            (acc, availability) => {
                const startTime = new Date(availability.startTime);
                const day = startTime.getDay();
                const startHour = startTime.getHours();
                acc[day][startHour] += 1;
                return acc;
            },
            Array.from({ length: 7 }, () => Array.from({ length: 24 }, () => 0)),
        );


        const numberOfObviousAvailabilities = 5;
        for (let day = 0; day < 7; day++) { // Iterate over each day
            // Add a certain number of obvious availabilities for each day
            for (let i = 0; i < numberOfObviousAvailabilities; i++) {
                const numberOfBlocks = Math.floor(Math.random() * 3) + 1; // Randomly choose 1, 2, or 3 blocks

                // Adjust the range based on the number of blocks to ensure they stay within 6 AM to 8 PM
                let maxHour = 20 - numberOfBlocks;
                let randomHour = Math.floor(Math.random() * (maxHour - 6)) + 6;

                for (let j = 0; j < numberOfBlocks; j++) {
                    availabilityMatrix[day][randomHour + j] += 20;
                }
            }
        }





        return this.delayedResponse(availabilityMatrix.map((dayOfWeekData: number[], index: number) => {
            return {
                data: dayOfWeekData,
                name: daysOfWeek[index]
            }
        }));
    }
    getBucketedSignups({ locationId, startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<BucketedSignupsDto> {
        const startTimeLimit = startTime || new Date(2022, 1, 1).toISOString();
        const endTimeLimit = endTime || new Date().toISOString();
        // Function to bucket sign-up times with dynamic bucket size
        function bucketSignups(users: { signUpTime: Date }[]) {
            const bucketedData: { time: Date; count: number; cumulative: number }[] =
                [];

            const minSignUpTime = new Date(startTimeLimit).getTime();
            const maxSignUpTime = new Date(endTimeLimit).getTime();

            // Calculate the time range
            const timeRange = maxSignUpTime - minSignUpTime;
            const numberOfDays = timeRange / (24 * 60 * 60 * 1000);
            let bracket = 24 * 60 * 60 * 1000;
            if (numberOfDays <= 3) bracket = 60 * 60 * 1000;
            else if (numberOfDays <= 7) bracket = 4 * 60 * 60 * 1000;
            else if (numberOfDays <= 31) bracket = 24 * 60 * 60 * 1000;
            else if (numberOfDays <= 90) bracket = 7 * 24 * 60 * 60 * 1000;
            else bracket = 30 * 24 * 60 * 60 * 1000;

            // Determine the number of buckets (adjust this based on your preference)
            const numBuckets = Math.ceil(timeRange / bracket); // One bucket per day

            // Calculate the dynamic bucket size
            const bucketSize = Math.ceil(timeRange / numBuckets);

            // Bucket sign-ups
            for (let i = 0; i < numBuckets; i++) {
                const currentBucketStart = new Date(minSignUpTime + i * bucketSize);
                const currentBucketEnd = new Date(minSignUpTime + (i + 1) * bucketSize);

                let count = 0;
                let cumulative = 0;
                users.forEach((user) => {
                    const signUpTime = new Date(user.signUpTime);
                    if (signUpTime < currentBucketEnd) cumulative++;
                    if (signUpTime >= currentBucketStart && signUpTime < currentBucketEnd)
                        count++;
                });

                bucketedData.push({ time: currentBucketStart, count, cumulative });
            }

            return bucketedData;
        }

        const bucketedData = bucketSignups(this.usersFixture);
        const start = new Date(startTimeLimit).getTime();
        const end = new Date(endTimeLimit).getTime();
        const withinThreshold = bucketedData.filter(
            (bucket) =>
                bucket.time.getTime() >= start && bucket.time.getTime() <= end,
        );

        return this.delayedResponse(withinThreshold);
    }
    getUserCount({ startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<UserCountDto> {
        console.log("getUserCount", { startTime, endTime })
        const usersWithinRange = this.usersFixture.filter(user => (!startTime || user.signUpTime.getTime() >= startTime.getTime()) && (!endTime || user.signUpTime.getTime() <= endTime.getTime()));
        // const usersWithinRange = this.usersFixture
        const userCountDto: UserCountDto = { count: usersWithinRange.length, expertiseDistribution: {}, genderDistribution: {}, totalCount: this.usersFixture.length };

        usersWithinRange.forEach((user) => {
            if (user.expertise !== undefined) {
                if (!userCountDto.expertiseDistribution[user.expertise])
                    userCountDto.expertiseDistribution[user.expertise] = 0;
                userCountDto.expertiseDistribution[user.expertise]++;
            }
            if (user.gender !== undefined) {
                if (!userCountDto.genderDistribution[user.gender])
                    userCountDto.genderDistribution[user.gender] = 0;
                userCountDto.genderDistribution[user.gender]++;
            }
        });
        return this.delayedResponse(userCountDto);
    }
    getLocations(): Promise<WorkoutLocation[]> {
        return this.delayedResponse<WorkoutLocation[]>([{
            id: '',
            shortName: 'Demo Gym',
            fullname: 'Demo Gym',
            address: '',
            coordinates: { lat: '', long: '' },
            activityTypesMetadata: []
        }]);
    }
    getFriendRequestsCount({ startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<{ count: number; }> {
        const userCount = this.getUsersWithinRange({ startTime, endTime });
        return this.delayedResponse({ count: Math.floor(userCount.length * 3.4) });
    }
    getClasses({ startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<ClassRow[]> {
        return this.delayedResponse(this.getClassesWithinRange({ startTime, endTime }));
    }
    getActivityCount({ startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<ActivityCountDto> {
        const activitiesWithinRange = this.getActivitiesWithinRange({ startTime, endTime });
        return this.delayedResponse({
            count: activitiesWithinRange.length,
            participationCount: Math.floor(activitiesWithinRange.length * 3.4)
        })
    }
    getActivityHeatmap({ startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<{ data: number[]; name: string; }[]> {
        const activities = this.getActivitiesWithinRange({ startTime, endTime });
        const heatmapData: ActivityHeatmapDto = [];

        // Initialize a 2D array to store counts for each day of the week and hour
        for (let i = 0; i < 7; i++) {
            heatmapData[i] = Array(24).fill(0);
        }

        activities.forEach((activity) => {
            const start = new Date(activity.startTime);
            const end = new Date(activity.endTime);

            // Convert start and end times to UTC
            const startUtc = new Date(start.toISOString());
            const endUtc = new Date(end.toISOString());

            // Ensure the event has a valid start and end time
            if (!isNaN(startUtc.getTime()) && !isNaN(endUtc.getTime())) {
                const dayOfWeek = startUtc.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
                const startHour = startUtc.getHours();

                // Check if the event spans multiple days
                const daysDiff = Math.floor(
                    (endUtc.getTime() - startUtc.getTime()) / (24 * 60 * 60 * 1000),
                );

                for (let i = 0; i <= daysDiff; i++) {
                    const currentDay = (dayOfWeek + i) % 7; // Wrap around if necessary
                    const currentHour = (startHour + i * 24) % 24; // Wrap around if necessary

                    heatmapData[currentDay][currentHour]++;
                }
            }
        });



        const numberOfObviousActivitytimes = 5;
        for (let day = 0; day < 7; day++) {
            for (let i = 0; i < numberOfObviousActivitytimes; i++) {
                const numberOfBlocks = Math.floor(Math.random() * 3) + 1;
                let maxHour = 20 - numberOfBlocks;
                let randomHour = Math.floor(Math.random() * (maxHour - 6)) + 6;

                for (let j = 0; j < numberOfBlocks; j++) {
                    heatmapData[day][randomHour + j] += 40;
                }
            }
        }


        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        return this.delayedResponse(heatmapData.map((dayOfWeekData: number[], index: number) => {
            return {
                data: dayOfWeekData,
                name: daysOfWeek[index]
            }
        }));
    }
    getActivityBuckets(input: { locationId: string; startTime: Date; endTime: Date; }): Promise<BucketedActivitiesDto> {
        throw new Error("Method not implemented.");
    }

    private delayedResponse<T>(data: T, delay: number = 0): Promise<T> {
        return new Promise<T>((resolve) => {
            if (delay)
                setTimeout(() => {
                    resolve(data);
                }, delay);
            else
                resolve(data);
        });
    }
}